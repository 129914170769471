.ticket-stories-table-container {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.ticket-stories-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.ticket-stories-table th,
.ticket-stories-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.ticket-stories-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.ticket-stories-table td {
  color: #333;
}

.ticket-stories-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.ticket-stories-table tr:hover {
  background-color: #f1f1f1;
}

.ticket-stories-table th,
.ticket-stories-table td {
  font-size: 16px;
}

/* Scrollable table body */
.table-body-container {
  max-height: 400px; /* Show only 10 rows before scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}

.table-body-container table {
  width: 100%;
}
