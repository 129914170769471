.statussuggestions-container {
  max-height: 200px; /* Set the maximum height for the suggestions container */
  overflow-y: auto; /* Enable vertical scrollbar when content exceeds max height */
  border: 1px solid #ccc;
  background-color: white;
  z-index: 10; /* Ensure the suggestions appear on top of other elements */
  position: relative; /* Positioning for dropdown */
  width: 100%;
  border-radius: 10px;
}

.search-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.report-container {
  margin-top: 20px;
  margin: -1px;
}
