
  .form-group label {
    font-weight: bold;
  }

  .btn-primary {
    background-color: #007bff;
    border: none;
  }

  .demo-icons-list-item {
  height: 40px; 
  }

  .datepicker {
    font-size: 14px; 
    width: 1px;  
}

.custom-card{
margin-left: 40px;
}

.row {
  margin: 20px 20px 20px 20px;
}

  .custom-selector {
    margin-top: 25px;
  }
  .custom_btn {
    margin-top: 30px;
    margin-bottom: 50px;
    height: 40px;
    margin-right: 40px;
  }
  .btn-primary:hover {
    background-color: #102739;
  }

  .form-heading {
    background-color: #007bff;
    color: #ffffff;
    padding: 10px;
    border-radius: 5px;
  }

