.search-form {
  display: flex;
  align-items: center; /* Aligns items on the same line */
  position: relative; /* For absolute positioning of suggestions */
  width: 45%;
}

.search-box {
  flex-grow: 1;
  width: 40vw;
  max-width: 1000px;
  height: 40px;
}

.search-button {
  margin-left: 8px;
  flex-shrink: 0;
  height: 40px;
  margin-right: 10px;
}

/* SearchBar.css */

.suggestions-container {
  max-height: 200px; /* Set the maximum height for the suggestions container */
  overflow-y: auto; /* Enable vertical scrollbar when content exceeds max height */
  border: 1px solid #ccc;
  background-color: white;
  z-index: 10; /* Ensure the suggestions appear on top of other elements */
  position: absolute; /* Positioning for dropdown */
  width: 100%; /* Full width of the input */
}

.suggestions-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}
