/* Active Page Button */
.pagination-btn.active {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pagination-container {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  margin-top: 10px; /* Add space between the table and pagination */
  width: 100%;
}

.pagination-btn {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  color: #007bff;
  padding: 10px 15px;
  margin: 0 2px;
  cursor: pointer;
}

.pagination-btn:hover {
  background-color: #e9ecef;
}

.pagination-btn.active {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

.pagination-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.ellipsis {
  padding: 8px 16px;
  margin: 0 4px;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
}

/* Additional styling for improved readability and accessibility */
.pagination-btn {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
}

.pagination-btn:focus {
  outline: none;
}

.ellipsis {
  font-size: 14px;
  font-weight: 500;
}

.pagination-btn .active {
  background-color: #007bff !important;
  color: #131111 !important;
  border: 1px solid #007bff !important;
  font-weight: bold !important; /* Add this line to make the selected page number stand out */
}
