.heading {
  border: 3px solid;
  border-radius: 5px;
  margin-top: 50px;
  width: 700px;
  font-size: large;
  font-weight: 200;
}

.name {
  font-size: large;
  margin-top: 10px;
}

.checkbox-item {
  margin-left: 15px;
}

/* CSS for proper alignment */
.checkbox-list {
  margin-bottom: 20px;
  /* Adjust the margin as needed between groups */
}

.checkbox-group-horizontal {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* Adjust the gap between checkboxes as needed */
}

.checkbox-item-horizontal {
  display: flex;
  align-items: center;
  margin-right: 10px;
  /* Adjust the margin between checkboxes as needed */
}

.checkbox-item-horizontal input {
  margin-right: 5px;
  /* Adjust the margin as needed between checkboxes and labels */
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 2px;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.checkbox-item input[type="checkbox"] {
  margin-right: 4px;
  margin-left: 20px;
}
