.tables-container {
  display: flex;
  flex-direction: column;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f8f9fa;
}

/* Add styles for the scrollable tables */
.table-wrapper {
  max-height: 400px; /* Set a fixed height for the table */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-bottom: 1rem; /* Add space between tables */
}

/* Optional: Add styles for table headers to make them stick to the top */
.table thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa; /* Set a background color for headers */
  z-index: 1; /* Ensure headers stay on top of the table body */
}
