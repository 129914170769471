.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .no-bg {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .no-bg:hover {
    background-color: transparent;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .product-details  {
    margin-left: 20px;
    margin-top: 10px;
  }
  
  h4 {
    margin-top: 20px;
  }
  
  .event-card {
    margin-left: 80px;
    margin-top: 60px;
  }
  
  .custom-modal {
    max-width: 800px; 
    margin: 0 auto; 
  }
  
  textarea {
    width: 70%;
    min-height: 120px; /* Adjust the height as needed */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: 80px;
    margin-top: 30px;
    resize: vertical; /* Allow vertical resizing */
  }
  
  /* Style the "Add Text Area" button */
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #102739;
  }
  
  /* Add this CSS to your stylesheet or style section */
  .dbValueTextArea {
    width: 60%;
    min-height: 100px; /* Adjust the minimum height as needed */
    border: 2px solid #ccc;
    padding: 12px;
    margin-bottom: 11px;
    margin-left: 16%;
    resize: none;
    background-color: #f5f5f5;
    font-size: 16px;
    color: #333;
    border-radius: 4px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    transition: border-color 0.2s ease-in-out; /* Smooth transition for border color */
  }
  
  /* Add a hover effect */
  .dbValueTextArea:hover {
    border-color: #007bff; /* Change border color on hover */
  }
  
  /* Add a focus effect */
  .dbValueTextArea:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove the default focus outline */
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.6); /* Add a stronger box shadow on focus */
  }
  
  .addBtn {
    margin-left: 75%;
    margin-top: 3%;
    margin-bottom: 3%;
    width: 23%;
    height: 40px;
  }
  
  .textarea-container {
    align-items: center;
  }
  
  .custom-textarea {
    flex: 1;
    margin-right: 10px; /* Add spacing between textarea and button */
  }
  
  
  .comment-textarea {
    margin-top: -4px;
    margin-left: -2px;
    height: 37px; /* Adjust the height as needed */
    resize: vertical; /* Allows vertical resizing */
    vertical-align: top; /* Aligns the text area content to the top */
  }
  
  .comment .comment-body {
    position: relative;
    padding: 24px;
    border: 1px solid #e1e7ec;
    border-radius: 7px;
    background-color: #fff
  }

  .my-custom-left-margin {
    margin-left:1350px; /* sub ticket back btn*/
}

  
  @media (max-width: 576px) {
    .comment {
        padding-left: 0
    }
    .comment .comment-author-ava {
        display: none
    }
    .comment .comment-body {
        padding: 15px
    }
   
  }
  