.page-body {
  padding: 20px;
  background-color: #f0f0f0;
}

.containers {
  display: flex;
  justify-content: space-evenly;
  background-color: #f0f0f0;
  padding: 20px 30px 30px;
  height:  180px;
}

.cards {
  flex: 0 0 calc(20% - 20px); 
  background-color: #f9f9f9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-right: 20px; 
  padding: 20px;
  text-align: center;
  position: relative;
}

.cards .icon {
  font-size: 20px;
  margin-top: -40px;
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  background-color: #3498db;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.large-icon .icon {
  font-size: 40px;
  width: 60px;
  height: 60px;
}

.cards-primary {
  background-color: #3498db;
  color: #fff;
}

.cards-success {
  background-color: #2ecc71;
  color: #fff;
}

.cards-warning {
  background-color: #e74c3c;
  color: #fff;
}

.accordion-button {
  background-color: #EFEFEF;
  color: #000000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Define the hover styles for the button */
.accordion-button:hover {
  background-color: #007BFF;
  color: #FFFFFF;
}

.cards-processing {
  background-color: #f9c846; 
  color: #fff;
}

.cards-danger {
  background-color: #f39c12;
  color: #fff;
}

.subheader {
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
}

.value {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.tickets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9; 
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.tickets-status a:hover {
  color: #70709b; 
}

.container {
  padding: 20px;
}

.card-header.text-center {
  text-align: center;
  font-weight: bold;
  background-color: #3498db;
  color: #fff;
  padding: 10px 0;
}

.custom-table {
  background-color: #f5f5f5;
  color: #333;
  border-collapse: collapse;
  width: 100%;
  border: 2px solid #8786c2;
  border-radius: 5px;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}


.custom-table tbody tr:hover {
  background-color: #e3e3e3;
}

.custom-table tbody tr:hover {
  background-color: #e0e0e0; 
}

.table-heading {
  text-align: center;
  color: #007bff; 
  margin-bottom: 20px; 
  font-weight: bold;
  font-size: 20px;
}

.custom-table th {
  text-align: center;
  font-weight: bold;
  color: #007bff;
  font-size: 16px;
}

.table-heading {
  text-align: center;
  color: #519473;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
}

/* Style the tables */
.custom-table {
  background-color: #f5f5f5;
  color: #333;
  border-collapse: collapse;
  width: 100%;
  border: 2px solid #8786c2;
  border-radius: 5px;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}

.custom-table tbody tr:hover {
  background-color: #e3e3e3;
}

.custom-table td {
  padding: 8px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.table-header {
  text-align: center;
  color: #4c80db;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 15px;
}

.custom-table tbody tr {
  background-color: #f7f7f7;
}

.custom-table tbody tr:hover {
  background-color: #e3e3e3;
}

.tickets-title {
  font-weight: bold;
  font-size: 16px;
  color: #007bff; 
  text-decoration: none; 
}

.tickets-title:hover {
  text-decoration: underline; 
}

.tickets-details span {
  font-size: 14px;
  color: #555; 
  margin-right: 10px;
}

.extra-design {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tickets-status:hover {
  color: #49549e; 
}

.due-date {
  background-color: #4caf50; 
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  text-decoration: none;
}

.due-date:hover {
  background-color: #45a049; 
}

.tasks {
  margin-left: 50px;
  margin-bottom: 50px;
  margin-top: 50px;
}
.custom-table tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}

.custom-table tbody tr:hover {
  background-color: #e3e3e3;
}
