.custom-modal select {
  /* Dropdown styles */
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f9fa; /* Light background color */
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); /* Subtle box shadow */
}

.custom-modal select:focus {
  /* Focus styles */
  outline: none;
  border-color: #66afe9; /* Border color on focus */
  box-shadow: 0 0 0 2px rgba(102, 175, 233, 0.6); /* Light blue glow on focus */
}

.custom-modal .modal-footer button {
  /* Button styles */
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #007bff; /* Blue background color */
  color: #fff; /* White text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.custom-modal .modal-footer button:hover {
  /* Button hover styles */
  background-color: #0056b3; /* Darker blue on hover */
}
