.comment-section {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center aligning for the entire section */
}

.comments-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.comment {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.comment-text {
  margin: 0;
}

.new-comment {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.bold-textarea {
  font-weight: bold;
  border: 1px solid #ced4da;
  padding: 8px;
  min-height: 100px;
}

/* CommentSection.css */
.comment {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.comment .comment-text {
  flex: 1;
}

.comment .btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px; /* Ensure the font size is appropriate for the icon */
  color: #000; /* Ensure the color is not the same as the background */
}
.textarea-wrapper {
  position: relative;
  width: 100%;
  padding-right: 80px; /* Add padding to move textarea to the right */
}

.comment-input {
  width: 100%; /* Adjusted width for better fit */
  height: 150px; /* Increased height to fit date inputs comfortably */
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  box-sizing: border-box;
  font-size: 16px; /* Increased font size for better readability */
  margin-left: 10%; /* Move textarea to the right */
}

.date-input {
  width: 45%; /* Adjusted width for date inputs */
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.add-comment-button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px; /* Increased font size for better readability */
}

.add-comment-button:hover {
  background-color: #0056b3;
}
