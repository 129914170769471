.report-container {
  margin: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.date-picker-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.date-picker {
  flex: 1;
  margin-right: 10px;
}

.date-picker:last-child {
  margin-right: 0;
}

.date-picker label {
  display: block;
  margin-bottom: 5px;
}

.date-picker .date-picker {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}

section {
  margin-bottom: 20px;
}

h2 {
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ddd;
}

th,
td {
  padding: 8px;
  text-align: center;
}

footer {
  text-align: center;
  margin-top: 20px;
  font-style: italic;
}

.loading,
.error {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
}
